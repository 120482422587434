<template>
  <b-sidebar
      id="add-new-created-sidebar"
      :visible="isAddNewCreatedSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="sideBarShown"
      @hidden="resetForm"
      :no-close-on-backdrop="true"
      @change="(val) => $emit('update:is-add-new-created-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить новая категория
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>


      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Наименование -->
          <validation-provider
              #default="validationContext"
              name="Наименование"
              rules="required"
          >
            <b-form-group
                label="Наименование"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="userData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Сохранить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, regex } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import http from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCreatedSidebarActive',
    event: 'update:is-add-new-created-sidebar-active',
  },
  props: {
    isAddNewCreatedSidebarActive: {
      type: Boolean,
      required: true,
    },
    createdId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
    }
  },
  methods: {
    sideBarShown() {
      this.getCreated()
    },
    getCreated() {
      if (this.createdId > 0) {
        this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/created-project/${this.createdId}/view`, {
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.userData = {
              title: data.title,
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение категории',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.userData = {
          name: '',
        }
      }
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      title: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'avatar') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }

    const onSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userData.value)
      if (props.createdId > 0) {
        http.post(`https://edu.startupchoikhona.tj/backend/api/created-project/update/${props.createdId}`, formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-created-sidebar-active', false)
          })
      } else {
        http.post('https://edu.startupchoikhona.tj/backend/api/created-project/create', formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-created-sidebar-active', false)
          })
      }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    localize('ru', ru)
    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
  }
}
</script>

<style lang="scss">

#add-new-created-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
